import { Apollo } from "apollo-angular";
import { graphqlv3 } from "gqlv3";
import { inject, Injectable } from "@angular/core";
import * as rxjsoperators from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
class NrnKit {
  static Namespace = "nrn";

  static Partition = "bwan";

  private _apollo = inject(Apollo);

  tenantNrn: string;

  async fetchTenantNrn() {
    try {
      if (this.tenantNrn) {
        return this.tenantNrn;
      }

      this.tenantNrn = await this._apollo
        .query({
          query: graphqlv3(`
          query Tenant {
            tenant {
              nrn
            }
          }
        `),
          context: {
            version: "3",
          },
        })
        .pipe(rxjsoperators.first())
        .toPromise()
        .then((response) => response.data.tenant?.nrn);

      return this.tenantNrn;
    } catch (e) {
      console.error(e);

      return "";
    }
  }

  parseNrn(nrn: string) {
    if (!nrn) {
      throw new Error(`Invalid ${NrnKit.Namespace}`);
    }

    const parts = nrn.split(":");

    if (parts?.length < 6) {
      throw new Error(`Invalid ${NrnKit.Namespace}`);
    }

    const nrnParts = {
      namespace: parts[0],
      partition: parts[1],
      service: parts[2],
      region: parts[3],
      tenantID: parts[4],
      resourceType: parts[5],
      resourceID: parts[6],
    };

    if (!nrnParts.tenantID) {
      throw new Error(`${NrnKit.Namespace} missing tenant id`);
    }

    if (!nrnParts.resourceType) {
      throw new Error(`${NrnKit.Namespace} resource type`);
    }

    if (!nrnParts.resourceID) {
      throw new Error(`${NrnKit.Namespace} resource id`);
    }

    return nrnParts;
  }

  async generateNrn(service: string, resourceType: string, resourceId: string): Promise<string> {
    const tenantNrn = await this.fetchTenantNrn();

    const { region, resourceID: tenantID } = this.parseNrn(tenantNrn);

    if (!service) {
      throw new Error("missing resource service");
    }

    if (!resourceType) {
      throw new Error("missing resource type");
    }

    if (!resourceId) {
      throw new Error("missing record id");
    }

    if (!tenantID) {
      throw new Error("missing tenant id");
    }

    if (!region) {
      throw new Error("missing region");
    }

    const nrnParts = [NrnKit.Namespace, NrnKit.Partition, service, region, tenantID, resourceType, resourceId];

    return nrnParts.join(":");
  }

  async getTenantNrnByID(tenantID: string): Promise<string> {
    const newTenantNrn = await this._apollo
      .query({
        query: graphqlv3(`
        query TenantNrnByID($tenantId: ID) {
          tenant(id: $tenantId) {
            nrn
          }
        }
      `),
        variables: {
          tenantId: tenantID,
        },
        context: {
          version: "3",
        },
      })
      .pipe(rxjsoperators.first())
      .toPromise()
      .then((response) => response.data.tenant?.nrn);

    return newTenantNrn;
  }
}

export default NrnKit;
