import _ from "lodash";
import * as infschemas from "@infiot/infschemas";
import { isNetworkInterface, isSubInterface, isUsbInterface } from "@infiot/infmgmtintf";
import { VnetIpsecTunnel } from "@infiot-fuse/services/inf-site-vpn-peers.service";
import { OverlayTag } from "gqlv3/graphql";

/* TODO: This is temporary. It will be removed once the wellKnownID is available. */
export const DEFAULT_WAN_LINK_TAG_NAME = "Wired";
export const DEFAULT_OVERLAY_TAG_NAME = "Public-Overlay";

// Shared state variables
let defaultWanLinkTag: OverlayTag | undefined;
let defaultOverlayTag: OverlayTag | undefined;

// Getter and Setter for `defaultWanLinkTag`
export function getDefaultWanLinkTag(): OverlayTag | undefined {
  return defaultWanLinkTag;
}

export function setDefaultWanLinkTag(value: OverlayTag | undefined): void {
  defaultWanLinkTag = value;
}

// Getter and Setter for `defaultOverlayTag`
export function getDefaultOverlayTag(): OverlayTag | undefined {
  return defaultOverlayTag;
}

export function setDefaultOverlayTag(value: OverlayTag | undefined): void {
  defaultOverlayTag = value;
}

export function generateRandomUniqueIDString(): string {
  return `${(Math.random() + 1).toString(36).substring(7)}-${new Date().valueOf()}`;
}

export const maxNumberOfSubInterfaceSupported = 31;
export const maxNumberOfSecondaryIpAddressSupported = 31;

export const isInterfaceEligibleToHaveSubInterface = (intf: infschemas.Interface | undefined): boolean =>
  isNetworkInterface(intf) && intf?.intf_mode === infschemas.IntfMode.routed && !isUsbInterface(intf);

export const getNativeVlanFieldName = (intf: infschemas.Interface): string =>
  isSubInterface(intf) ? "intf_subif_type" : "intf_switch_type";

export const isInterfaceAssociatedWithTunnel = (
  segments: infschemas.VirtualNetworkSettings[],
  interfaceName: string,
): boolean =>
  segments
    .reduce((acc: VnetIpsecTunnel[], segment) => acc.concat(segment.vnet_ipsec_tunnels || []), [])
    .some((tunnel) => tunnel.intf_name === interfaceName);

export function assignIntfNameIdInClientIpPool(
  templateVirtualNetwork: infschemas.VirtualNetwork,
  gatewayVirtualNetwork: infschemas.VirtualNetwork,
) {
  templateVirtualNetwork.forEach((network, index) => {
    const templateIpPoolRange = network?.client_ip_pool?.ipv4_pool_range;
    if (templateIpPoolRange && templateIpPoolRange.length > 0) {
      templateIpPoolRange.forEach(({ intf_name_id }, poolIndex) => {
        if (intf_name_id) {
          const gatewayIpPoolRange = gatewayVirtualNetwork[index].client_ip_pool?.ipv4_pool_range;
          if (gatewayIpPoolRange && gatewayIpPoolRange.length > poolIndex) {
            gatewayIpPoolRange[poolIndex]["intf_name_id"] = intf_name_id;
          }
        }
      });
    }
  });

  return _.cloneDeep(gatewayVirtualNetwork);
}
